import React, {Component, useState, useEffect, useMemo} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {WebLink, SiteWide} from "./customComponents";
import Section from "./layouts/Section";

import Slider from "react-slick";
import {Images} from "./customComponents";
import Colors from '../utility/Colors';

import {fxActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allFXSelect, userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import Pagination from './customComponents/pagination/Pagination';

    
let PageSize = 10;

function FX() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loading, setloading]   = useState(false);

    const dispatch = useDispatch();
    const loadFxData = useSelector(allFXSelect);



    const whichFxToUse = loadFxData?.fx?.filter((item, index) => !isNaN(item.result) && (item.result !==null));

    const [currentPage, setCurrentPage] = useState(1);
    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return whichFxToUse?.slice(firstPageIndex, lastPageIndex);
    };
    const changeThisPage = (page) => {
        setCurrentPage(page); 
        window.scrollTo(0, 0);
    }


    const loadAllFXes = () => {

        setloading(true)

        dispatch(fxActions.onGetFxHead("fx/load_all", {}, response => {
            if(response.code == 1){
                setloading(false)
            }
        }))

    }

    useEffect(() => {
        loadAllFXes()
    }, [])

    const writeDownOrUp = (zeroOrOne) => {
        if(zeroOrOne == 0){
            return 'down'
        }else if(zeroOrOne ==1){
            return 'up'
        }else{
            return 'Loading...'
        }

    }


    return (

        <SiteWide title={"FX History"}>
            <Section back="/trade" sectionstyle={{marginTop: '50px'}} loading={loading} title={'FX History'}
                 rightTopMenu={<span style={{cursor:'pointer'}} onClick={loadAllFXes}>Refresh {loading && <i className="fa fa-spin fa-pulse fa-spinner"></i>}</span>}
                >

                <div className="goal-area pd-bottom-36">
                    <div className="containe" style={{paddingRight: 20, paddingLeft: 20}}>
                        
                        {/*<div className="section-title" style={{display: 'flex',}}>
                            <h3 style={{}} className="title text-grey">FX History</h3>
                        </div>*/}

                        <ul className="transaction-inner">
                            {currentTableData()?.map((item, index) => {

                                    return <li className="ba-single-transaction style-two bg-black-grad">
                                        <div className="thumb" style={{backgroundColor: 'transparent', border: 0}}>
                                            <Images style={{height: '70px'}} alt={`${item?.name} ticker crypto`} source={require(`../images/fx_${item?.result}.png`)} />
                                        </div>
                                        <div className="details">
                                            <h5 className="text-white">{item?.fxId}</h5>
                                            <small style={{fontSize: 9}} className="text-info">{item?.timeEnd}</small>

                                            <p className="text-white"></p>
                                            <h5 className={"text-center amount bg-info"} style={{color: '#fff'}}>
                                                <Images style={{height: '70px'}} alt={`Direction`} source={require(`../images/dir_${item?.result}.png`)} />
                                            </h5>
                                        </div>
                                    </li>
                                }
                            )}
                        </ul>
                        
                        {whichFxToUse?.length > 0 && <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={whichFxToUse?.length}
                            pageSize={PageSize}
                            onPageChange={page => changeThisPage(page) }
                          />
                      }
                        <br />

                        {loadFxData?.fxTradehistory?.length == 0 && !loading && <div>
                                <h5 className="text-warning">No history found.</h5>

                            </div>
                        }



                    </div>
                </div>

                <Footer />

            </Section>
        </SiteWide>
    );
}

export default FX;