import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';




function* onManageWithdraw(action) {
  try {
    const response = yield (new APIRequests).runPost(action.routeToCall, action.param);
    if (response.code == 1) {

      response.data.full_data.user.token = response.access_token;

      yield put({ type: actionTypes.SAVE_WITHDRAWAL_DATA, data: response.data.withdrawals });

      yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.full_data.user });
      yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.full_data.finance });
      yield put({ type: actionTypes.SAVE_REMOTE_SITE_SETTINGS, settings: response.data.full_data.settings }); 


    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* onLoadWithdraws(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);
    if (response.code != 0) {
      yield put({ type: actionTypes.SAVE_WITHDRAWAL_DATA, data: response.data.withdrawals});
    }
    return action.callback?.(response);
  } catch (error) {
    console.log(error.response)
    return action.callback?.(error.response ?? error.message);
  }
}



function* watchOnManageWithdraw() {
  yield takeEvery(actionTypes.MANAGE_WITHDRAWAL, onManageWithdraw);
}



function* watchOnLoadWithdraws() {
  yield takeEvery(actionTypes.ALL_WITHDRAWALS, onLoadWithdraws);
}



export default function* withdrawSagas() {
  yield all([watchOnManageWithdraw(), watchOnLoadWithdraws()]);
}
