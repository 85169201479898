import * as actionTypes from "./actionTypes";

export const onGetFxHead = (routeToCall, params, callback = () => {}) => {
  return {
    type: actionTypes.FETCH_FX_HEAD,
    params,
    callback,
    routeToCall,
  };
};
